<template>
    <div class="container-fluid my-4">
        <h1 class="mb-2">{{$t('amlSessions.view.title')}}</h1>
        <div class="bg-white shadow p-4 rounded">
            <aml-sessions-table
                :dataset="amlSessions"
                :loading="amlSessionsLoading"
                @show="onShow"
            />
        </div>
    </div>
</template>

<script>
import DataViewBase from '../../../../../../Components/DataViewBase.vue'
import AmlSessionsTable from './Components/AmlSessionsTable.vue'
export default {
    name: 'ClientSessionsList',
    extends: DataViewBase,
    components: {
        AmlSessionsTable
    },
    data () {
        return {
            amlSessions: [],
            amlSessionsLoading: true
        }
    },
    mounted () {
        this.loadAmlSessions()
    },
    methods: {
        async loadAmlSessions () {
            this.amlSessionsLoading = true
            try {
                const response = await this.$api.amlSessions.read()
                this.amlSessions = response.data
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('clientSessions.view.error.loadSessions'))
            } finally {
                this.$nextTick(() => {
                    this.amlSessionsLoading = false
                })
            }
        },
        onShow (amlSession) {
            this.$router.push({ name: 'AdminAmlSessionDetail', params: { amlSessionId: amlSession._id } })
                .catch(() => {})
        }
    }
}
</script>
