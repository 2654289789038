<template>
    <data-table
        :data="dataset"
        :loading="loading"
        :buttons="buttons"
        :header="header"
        :responsive="true"
        class="table-sm-font"
        :auto-update="false"
        :lang="lang"
        :paging="true"
        :actions="true"
        @action="onAction"
    />
</template>

<script>

export default {
    name: 'ClientSessionTable',
    props: {
        dataset: {
            type: Array,
            required: false,
            default: () => []
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        header () {
            return [
                {
                    text: this.$t('clientSessions.table.id'),
                    data: '_id',
                    filterable: true,
                    sortable: true
                },
                {
                    text: this.$t('clientSessions.table.clientType'),
                    data: 'clientType',
                    filterable: true,
                    sortable: true,
                    format: (value) => {
                        return this.$t(`clientSessions.clientType.${value}`)
                    }
                },
                {
                    text: this.$t('clientSessions.table.clientName'),
                    data: 'clientName',
                    filterable: true,
                    sortable: true
                },
                {
                    text: this.$t('clientSessions.table.phone'),
                    data: 'phone',
                    filterable: true,
                    sortable: true
                },
                {
                    text: this.$t('clientSessions.table.email'),
                    data: 'email',
                    filterable: true,
                    sortable: true
                },
                {
                    text: this.$t('clientSessions.table.state'),
                    data: 'state',
                    filterable: true,
                    sortable: true,
                    format: (input) => {
                        return this.$t(`clientSessions.state.${input}`)
                    }
                },
                {
                    text: this.$t('clientSessions.table.sentToIvan.heading'),
                    data: 'sentToIvan',
                    filterable: true,
                    sortable: true,
                    format: (input) => {
                        return input ? this.$t('app.yes') : this.$t('app.no')
                    }
                },
                {
                    text: this.$t('clientSessions.table.stateChangedAt'),
                    data: 'stateChangedAt',
                    filterable: true,
                    sortable: true,
                    format (value) {
                        return (value) ? new Date(value).toLocaleString() : value
                    }
                }
            ]
        },
        buttons () {
            return [
                {
                    event: 'show',
                    text: this.$t('dataTable.show'),
                    variant: 'primary'
                }
            ]
        },
        lang () {
            return (this.$store.state.lang === 'cz') ? 'cs_CZ' : 'en_US'
        }
    },
    methods: {
        onAction (event) {
            this.$emit(event.event, event.row)
        }
    }
}
</script>
